<template>
    <v-app ref="blurRef" :class="{ blur: showCredits, mobile: smAndDown }" :theme="store.theme" fluid>
        <v-navigation-drawer v-if="!smAndDown && !/deja|dad/i.test($route.path)" order="2" width="200" floating location="left">
            <div class="h-100 d-flex align-center"></div>
        </v-navigation-drawer>
        <nav-header v-if="routerIsReady && !/\/(dad|worker.*|advoid|deja|batteries)/i.test($route.path)" :imageBannerHeight="imageBannerHeight" :theme="store.theme" @update:theme="updateTheme" :signup="signup" />
        <v-main :style="`position: absolute; top: ${/\/(account|dad|worker.*|advoid|deja|batteries)/.test($route.path) ? 0 : imageBannerHeight}px; overflow-x: hidden`" width="100%" :height="/\/(deja|batteries|dad)/.test($route.path) ? '100%' : ''">
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component" @update:theme="updateTheme" @error="errorHandler" @signup="signup = true" />
                </keep-alive>
            </router-view>
        </v-main>
        <v-navigation-drawer v-if="!smAndDown && !/deja|dad/i.test($route.path)" order="2" width="200" floating location="right">
            <div class="h-100 d-flex align-center"></div>
        </v-navigation-drawer>
        <v-snackbar text :timeout="-1" v-model="snackbar.active" style="opacity: 0.95" @click="snackbarCloseHandler" :color="store.theme === 'dark' ? 'grey-lighten-4' : 'grey-darken-4'">
            <v-row>
                <v-col cols="1" class="d-flex align-center justify-center py-0">
                    <v-icon :icon="snackbar.icon" :color="snackbar.iconColor" :size="smAndDown ? 'x-small' : 'x-large'" class="ml-2" />
                </v-col>
                <v-col cols="10" class="d-flex align-center justify-center py-0">
                    <span v-if="snackbar.message" v-html="snackbar.message"></span>
                    <span v-else @click="reload" class="font-weight-light" v-bind:class="smAndDown ? 'caption' : ''" style="cursor: pointer">App update available.</span>
                </v-col>
                <v-col cols="1" class="d-flex align-center justify-center py-0">
                    <v-btn variant="plain" :size="smAndDown ? 'x-small' : 'default'" @click="snackbarCloseHandler" icon="cancel" />
                </v-col>
            </v-row>
        </v-snackbar>
    </v-app>
</template>
<style>
.kg-image {
    max-width: -webkit-fill-available;
    object-fit: contain;
}

.image-wrapper::before,
.image-wrapper::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100px;
    pointer-events: none;
    z-index: 1;
    /* On top of the images */
}

.image-wrapper::before {
    top: 0;
    height: 30px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.image-wrapper::after {
    bottom: 0;
    height: 20px;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
</style>
<script setup>
import { ref, getCurrentInstance, onMounted, watch, provide, onBeforeMount } from 'vue'
import { useAppStore } from '@/store/app'
import { useDisplay } from 'vuetify/lib/framework.mjs'
import { until } from 'async'
import { useRoute, useRouter } from 'vue-router'
import io from 'socket.io-client'
import emitter from '@/plugins/eventBus'
import 'animate.css'
import { nanoid } from 'nanoid'
import posthog from 'posthog-js'

import NavHeader from '@/components/nav/NavHeader.vue'

posthog.init('phc_MYdJD4PZck0m9cXqUzroogexS0mtZa6LbHjobl4fDYD',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'always'
    }
)
const blurRef = ref()
const store = useAppStore()
store.apiCalls = {}
const { MODE } = import.meta.env
const resetLocalStorage = ref(false)
const loading = ref({
    default: false
})
const routerIsReady = ref(false)
const progressColor = ref('red')
const progressWidth = ref(1)
const sio = ref([])
const signup = ref(false)
const { smAndDown } = useDisplay()
const imageBannerHeight = ref(smAndDown.value ? 150 : 250)
const route = useRoute()
const router = useRouter()
const emit = defineEmits(['update:theme', 'error', 'signup'])
const { VITE_APP_SERVER } = import.meta.env
const error = ref(false)
const { $api, $keycloak } = getCurrentInstance().appContext.config.globalProperties
const version = ref()
const snackbarDefault = {
    active: false,
    icon: 'info',
    message: undefined,
}
const processed = ref({})
const snackbar = ref({ ...snackbarDefault })
const lastBuild = ref()
const showCredits = ref(false)
const versionCheckIntervalId = ref()
const buildInfo = ref()
const eventsRef = ref(store.events)

const checkVersion = async () => {
    buildInfo.value = await $api.buildInfo()

    if (!buildInfo.value?.build_date) {
        return
    }
    version.value = buildInfo.value.commit_sha
    if (lastBuild.value && lastBuild.value?.build_date !== buildInfo.value.build_date) {
        snackbar.value.active = true
        // TODO: put some extra check here...
        resetLocalStorage.value = true
    } else {
        lastBuild.value = buildInfo.value
    }
}
function errorHandler(errorMessage) {
    error.value = true
    snackbar.value = {
        active: true,
        icon: 'error',
        iconColor: 'red',
        message: errorMessage,
    }
}
function snackbarCloseHandler() {
    snackbar.value.active = false
    setTimeout(() => {
        snackbar.value = { ...snackbarDefault }
        if (error.value) {
            error.value = false
        }
    }, 500)
}
function reload() {
    const url = new URL(window.location.href)
    url.searchParams.set('cache', Date.now())
    window.location.href = url.toString()
}
checkVersion()

versionCheckIntervalId.value = setInterval(checkVersion, 60000)

async function asyncInit() {
    router.isReady().then(() => {
        routerIsReady.value = true
        if (/advoid|dad/i.test(route.path)) {
            return
        }
        if (route.query.response === 'forbidden') {
            snackbar.value.message = `You do not have permission to access the ${route.redirectedFrom?.path || ''} resource.<br>Your information has been logged.`
            snackbar.value.icon = 'warning'
            snackbar.value.iconColor = 'warning'
            snackbar.value.active = true
        }
        sio.value[0] = io(VITE_APP_SERVER + '/blur', {
            transports: ['websocket'],
            autoConnect: false,
        })
        sio.value[1] = io(VITE_APP_SERVER + '/blur/account', {
            transports: ['websocket'],
            autoConnect: false,
        })
        sio.value[2] = io(VITE_APP_SERVER + '/blur/worker', {
            transports: ['websocket'],
            autoConnect: false,
        })

        sio.value[2]
            .on('connect', () => {
                console.info('connected to BLUR worker namespace')
            })
            .on('connect_error', error => {
                emit('error', error.message)
            })
            .on('disconnect', reason => {
                console.info('disconnected from BLUR worker namespace: ', reason)
            })
            .on('error', ({ eventId, message }) => {
                store.eventErrors[eventId] = message
                delete eventsRef.value[eventId]
            })
            .on('jobUpdated', (payload) => {
                emitter.emit('jobUpdated', payload)
            })
        sio.value[1]
            .on('connect', () => {
                console.info('connected to BLUR account namespace')
            })
            .on('connect_error', error => {
                emit('error', error.message)
            })
            .on('disconnect', reason => {
                console.info('disconnected from BLUR account namespace: ', reason)
            })
            .on('error', ({ eventId, message }) => {
                store.eventErrors[eventId] = message
                delete eventsRef.value[eventId]
            })
            .on('statusUpdated', (payload) => {
                emitter.emit('statusUpdated', payload)
            })

        sio.value[0]
            .on('connect', () => {
                console.info('connected to BLUR public namespace')
            })
            .on('connect_error', error => {
                emit('error', error.message)
            })
            .on('disconnect', reason => {
                console.info('disconnected from BLUR public namespace: ', reason)
            })
            .on('error', errorMessage => {
                const defaultColor = progressColor.value
                const defaultWidth = progressWidth.value
                progressColor.value = 'red'
                progressWidth.value = 2
                error.value = true
                setTimeout(() => {
                    error.value = false
                    loading.value.default = false
                    setTimeout(() => {
                        progressColor.value = defaultColor
                        progressWidth.value = defaultWidth
                    })
                }, 2500)
                emit('error', errorMessage)
            })
        if (!/\/deja/.test(route.path)) {
            sio.value[0].connect()
        }
    })
    await $keycloak.value.isLoaded
    if ($keycloak.value.isAuthenticated) {
        const isAuthorizedWorker = $keycloak.value.hasResourceRole('client-worker', 'blur')
        const isAdmin = $keycloak.value.hasResourceRole('admin', 'blur')

        if (isAuthorizedWorker) {
            await until(
                callback => callback(null, sio.value[2]),
                async () => await new Promise(resolve => setTimeout(resolve))
            )
            sio.value[2].auth = { token: $keycloak.value.token }
            sio.value[2].connect()
        }
        if (!isAuthorizedWorker || MODE !== 'production' || isAdmin) {
            await until(
                callback => callback(null, sio.value[2]),
                async () => await new Promise(resolve => setTimeout(resolve))
            )
            sio.value[1].auth = { token: $keycloak.value.token }
            sio.value[1].connect()
        }
    }
}
function themeHack(theme) {
    if (theme === 'dark') {
        document.body.style.backgroundColor = '#000000'  // Dark theme background
    } else {
        document.body.style.backgroundColor = '#FFFFFF'  // Light theme background
    }
}
onBeforeMount(asyncInit)
onMounted(() => {
    asyncInit()
    // window.addEventListener('beforeunload', beforeUnload)
    themeHack(store.theme)
    watch(eventsRef.value, events => {
        processEvents(events)
    })
    watch(() => signup.value, () => {
        setTimeout(() => {
            if (signup.value) {
                signup.value = false
                $keycloak.value.login({ redirectUri: `${window.location.origin}/worker-signup` })
            }
        })
    })
    watch(() => store.theme, theme => {
        themeHack(theme)
    })
})
function beforeUnload() {
    store.cleanupEvents()
    if (resetLocalStorage.value) {
        localStorage.clear()
        resetLocalStorage.value = false
    }
}
function processEvents(events) {
    if (Object.keys(events)?.length) {
        Object.values(events)
            .filter(event => event.name)
            .sort((a, b) => (a.timestamp > b.timestamp ? -1 : 0))
            .map(event => {
                const processedId = event.name
                if (!processed.value[processedId]) {
                    processed.value[processedId] = event
                    // some events aren't reset here... should probably change this.
                    setTimeout(() => delete processed.value[processedId], 3000)

                    async function handleSocketEvent(socketIndex, event) {
                        // console.log('handleSocketEvent: ', event, processed.value[processedId])
                        await until(
                            callback => callback(null, sio.value[socketIndex]?.connected),
                            async () => await new Promise(resolve => setTimeout(resolve))
                        )

                        sio.value[socketIndex].emit(event.name, event, response => {
                            if (event.name === 'checkCaptcha') {
                                store.captchaResponse = response
                            }
                            if (response && typeof response === 'object' && /error/i.test(response.name)) {
                                store.eventErrors[event.id] = response
                                delete processed.value[processedId]
                                delete eventsRef.value[event.id]
                            } else {
                                store.eventResponses[event.id] = response
                                delete processed.value[processedId]
                                delete eventsRef.value[event.id]
                            }
                            emitter.emit(`${event.name}:response`, event.id)
                        })
                    }
                    if (/getJobs|updateJob/.test(event.name) && sio.value[2]?.auth) {
                        handleSocketEvent(2, event)
                    } else if (/workerSignup|confirmAddress|updateAddress|getStatuses|createPaymentIntent|createSetupIntent|applyDiscountCode|mapAddressToIntent|getPresignedURL|updateDeliverableStatus/.test(event.name) && sio.value[1].auth) {
                        handleSocketEvent(1, event)
                    } else {
                        handleSocketEvent(0, event)
                    }
                }
            })
    }
}
function cacheIsOld(timestamp = Date.now(), maxAge = store.apiCalls.maxAge) {
    const expires = timestamp + maxAge
    const isOld = !timestamp || Date.now() > expires

    const red = '\x1b[31m'
    const green = '\x1b[32m'
    const reset = '\x1b[0m'
    console.log(`cache (${isOld ? `${red}EXPIRED${reset}` : `${green}valid${reset}`}): timestamp:${new Date(timestamp).toLocaleString()}, expires: ${new Date(expires).toLocaleString()} (${(expires - Date.now()) / 1000}) secs`)
    return isOld
}
async function hashString(message, algorithm = 'SHA-1') {
    const encoder = new TextEncoder()
    const data = encoder.encode(message)
    const hashBuffer = await crypto.subtle.digest(algorithm, data)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
    return hashHex
}
function updateTheme() {
    store.theme = store.theme === 'dark' ? 'light' : 'dark'
    emit('update:theme')
}
function debouncer(func, delay) {
    let debounceTimeout

    return (...args) => {
        if (debounceTimeout) clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
            func.apply(this, args)
        }, delay)
    }
}

async function getPresignedURL(key) {
    const eventId = nanoid()

    store.events[eventId] = {}

    store.emit({
        id: eventId,
        timestamp: Date.now(),
        name: 'getPresignedURL',
        payload: {
            key,
        },
    })
    await until(
        callback => callback(null, !store.events[eventId]),
        async () => await new Promise(resolve => setTimeout(resolve))
    )
    return store.eventResponses[eventId]
}
function cleanPayload(data) {
    const payload = Object.fromEntries(Object.entries({
        ...data,
    }).filter(([_key, value]) => value))

    return payload
}
provide('cleanPayload', cleanPayload)
provide('debouncer', debouncer)
provide('getPresignedURL', getPresignedURL)
provide('hashString', hashString)
provide('cacheIsOld', cacheIsOld)
provide('idToken', () => $keycloak.idTokenParsed)
</script>
