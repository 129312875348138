import { defineStore } from "pinia"
import { nextTick } from "vue"
import { nanoid } from "nanoid"

const { MODE } = import.meta.env
const reset = {
    checkout: {
        timestamp: undefined,
        idempotencyKey: nanoid(),
        discountApplied: undefined,
        discountCodeExpansion: undefined,
        discountCode: undefined,
        setupIntent: undefined
    },
    deja: {
        theme: 'light',
        clientIdRecordedByExtension: false,
        stats: {},
        linked: {},
        linkedActiveId: undefined,
        settings: undefined,
        state: {},
        settings: {
            bounce: false,
            bounceSpeed: 1,
            lyrics: true,
        }
    }
}

export const useAppStore = defineStore("app", {
    state: () => ({
        batteries: {
            userId: undefined,
            importedDeviceIds: []
        },
        events: {},
        eventTimestamps: {},
        eventResponses: {},
        eventErrors: {},
        theme: 'dark',
        splashed: false,
        banners: {
            share: {
                disabled: false
            }
        },
        faq: {
            category: 0,
            question: undefined
        },
        help: {
            categoryIndex: 0,
            article: undefined
        },
        contactForm: {
            sent: undefined,
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            department: 'sales',
            inquiry: undefined
        },
        createQueue: {},
        captchaResponse: undefined,
        checkout: JSON.parse(JSON.stringify(reset.checkout)),
        debounce: {},
        account: {
            panels: [0, 1]
        },
        hideAccountMenu: true,
        stats: {
            storage: 0,
            timestamp: undefined
        },
        tours: {
            letter: {}
        },
        eagerlyLoaded: {},
        apiCalls: {
            maxAge: MODE === 'production' ? 60000 * 5 : 30000
        },
        confirmedAddressHashes: {},
        workerDashboard: {
            sortBy: [{ key: 'createdOn', order: 'desc' }]
        },
        uploadForm: {
            files: [],
        },
        googlePanoTool: {
            streetViewURL: undefined,
            downloaded: {}
        },
        admin: {
            devView: false
        },
        advoid: {
            globalStats: {}
        },
        deja: JSON.parse(JSON.stringify(reset.deja)),
        nicer: true,
    }),
    actions: {
        emit(event) {
            nextTick(() => {
                this.events[event.id] = event
            })

            return Date.now()
        },
        resetCheckout(state) {
            state.checkout = JSON.parse(JSON.stringify({
                ...reset.checkout,
                idempotencyKey: nanoid()
            }))
        },
        resetDiscount(state) {
            state.checkout.discountApplied = undefined
            state.checkout.discountCode = undefined
        },
        resetDeja(state) {
            state.deja = JSON.parse(JSON.stringify({
                ...reset.deja,
            }))
        },
        cleanupEvents() {
            if (import.meta.env.MODE !== 'production') {
                console.log('cleaning... 🧹🧹🧹', import.meta.env.MODE)
                this.events = {}
                this.eventTimestamps = {}
                this.eventResponses = {}
                this.eventErrors = {}
                return
            }

            const now = Date.now()
            const fiveMinutesAgo = now - 5 * 60 * 1000
            const unique = {}

            for (const key in this.events) {
                console.log('key', key)
                const eventName = this.events[key]?.name
                const eventTimestamp = this.eventTimestamps[key]

                if (!unique[eventName] || unique[eventName]?.timestamp < this.events[key].timestamp) {
                    unique[eventName] = this.events[key]
                }
                if (eventTimestamp && eventTimestamp < fiveMinutesAgo) {
                    console.log('deleting........................', key)
                    // Delete entries older than 5 minutes
                    delete this.eventTimestamps[key]
                    delete this.eventResponses[key]
                    delete this.eventErrors[key]
                } else {
                    this.eventTimestamps[key] = Date.now()
                }
            }
            this.events = Object.values(unique).reduce((events, event) => {
                return event?.id ? { ...events, [event.id]: event } : events
            }, {})
            for (const key in this.eventTimestamps) {
                if (!(this.eventTimestamps[key] < fiveMinutesAgo * 2)) delete this.eventTimestamps[key]
            }
            for (const key in this.eventResponses) {
                if (!this.events.hasOwnProperty(key)) delete this.eventResponses[key]
            }
            for (const key in this.eventErrors) {
                if (!this.events.hasOwnProperty(key)) delete this.eventErrors[key]
            }
            console.log('cleaned events: ', Object.keys(this.events))
        },
    },
    persist: true,
})
